// Z-Index Guide:
//    No two component types should occupy the same decade 
//    Regular Page content should be < 30
//    Tabs: 31 
//  Free: 41-50
//    Clickaway Listeners: 51       <-- Always 3 less than the menu zIndex
//    Dialog: 52
//    Menu Clickaway Listeners: 53  <-- Click away listener for just menus
//    Menus: 54
//  Free: 61-70
//  Free: 70+ 

$font-family: 'Inter', sans-serif;

$text: rgba(0, 0, 0, 0.87);

$gray-label-text: rgba(0, 0, 0, 0.6);

$gray-3: rgba(0, 0, 0, 0.03);
$gray-6: rgba(0, 0, 0, 0.06);
$gray-26: rgba(0, 0, 0, 0.26);
$gray-40: rgba(0, 0, 0, 0.4);

$gray-75: #757575;

$home-card-gray: $gray-75;

$white: #ffffff;
$table-alternate-bg: #f5f5f5;

// Main Triad of Colors
$darkest-accent: #05182a;
$main-secondary-color: #0d3b69;
$main-button-highlight: #1976D2;
$primary-50: rgba($main-button-highlight, 0.5);

// Icons
$main-icon-color: #828282;
$icon-scale-factor: 1.08;

// Text
$main-header-color: #434343;

// Menus
$hover-option-color: #deebff;
$menu-box-shadow: rgb(0 0 0 / 10%) 1px 0px 6px 1px, rgb(0 0 0 / 10%) 0px 4px 11px;
$menu-z-index: 54;
$dialog-z-index: 52;

// Misc
$border-grey: #c2c2c2;
$disabled-color: #e6e6e6;
$click-away-z-index: 51;
$menu-click-away-z-index: 53;
$pending-change-indicator-color: #FFF9C4;
$main-background-color: #f4f4f4;
$recording-indicator-color: #e55353;

$valid-insight-green: #A5D6A7;
$invalid-insight-red: #EF918F;

$timing-function-1: cubic-bezier(0.4, 0, 0.2, 1);

$scoring-toolbar-bg: #fafafa;

$text-selection-color: rgb(151 207 224);

:export {
  white: $white;
  tableAlternateBG: $table-alternate-bg;

  // Main Triad of Colors
  darkestAccent: $darkest-accent;
  mainSecondaryColor: $main-secondary-color;
  mainButtonHighlight: $main-button-highlight;

  // Icons 
  mainIconColor: $main-icon-color;
  iconScaleFactor: $icon-scale-factor;

  // Text
  mainHeaderColor: $main-header-color;
  text: $text;
  
  // Menus
  hoverOptionColor: $hover-option-color;
  menuBoxShadow: $menu-box-shadow;
  menuZIndex: $menu-z-index;
  
  // Misc
  borderGrey: $border-grey;
  disabledColor: $disabled-color;
  clickAwayZIndex: $click-away-z-index;
  menuClickAwayZIndex: $menu-click-away-z-index;
  dialogZIndex: $dialog-z-index;
  pendingChangeIndicatorColor: $pending-change-indicator-color;
  mainBackgroundColor: $main-background-color;

  validInsightGreen: $valid-insight-green;
  invalidInsightRed: $invalid-insight-red;

  grayLabelText: $gray-label-text;
  primary50: $primary-50;
  gray40: $gray-40;
  gray3: $gray-3;
  gray6: $gray-6;
  gray26: $gray-26;
  fontFamily: $font-family;

  timingFunction1: $timing-function-1;

  homeCardBoldGray: $home-card-gray;
  entryCardBoldGray: $home-card-gray;
  recordingIndicatorColor: $recording-indicator-color;

  scoringToolbarBg: $scoring-toolbar-bg;

  textSelectionColor: $text-selection-color;
}


